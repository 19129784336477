@font-face {
  font-family: "Rubik";
  src: local("Rubik-VariableFont_wght"),
    url("./data/fonts/Rubik-VariableFont_wght.ttf") format("truetype");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  font-family: "Rubik", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.lang-selector {
  display: flex;
  direction: column;
  margin: 2% 0 2% 0;
}

.flag {
  clip-path: circle();
  width: min(16vw, 6em);
}





/* .gem-gif {
  width: 5em;  
}

@media (min-width: 600px) {
  .gem-gif {
    width: 10em;
  }
} */